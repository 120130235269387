<template>
    <li class="ui-dropdown-item">
        <component
            class="dropdown-item ui-dropdown-item__button"
            v-bind="$attrs"
            v-sanitize-href="link"
            :is="getComponent"
            :class="[{ disabled: disabled }]"
            :to="to"
            :aria-disabled="disabled"
        >
            <slot></slot>
        </component>
    </li>
</template>

<script>
export default {
    name: 'BDropdownItem',
    inheritAttrs: false,
    props: {
        link: {
            type: String,
            default: null,
        },
        to: {
            type: [String, Object],
            default: null,
        },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        getComponent() {
            if (this.to) return 'router-link';
            if (this.link) return 'a';
            return 'button';
        },
    },
}
</script>

<style lang="scss">
.ui-dropdown-item {
    &__button {
        font-size: 1rem;
        color: $general-black;
    }
}
</style>
