<template>
    <div class="ui-dropdown dropdown">
        <ui-button
            type="button"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            data-bs-boundary="window"
            :variant="variant"
        >
            <slot name="button-content"></slot>
        </ui-button>
        <ul class="dropdown-menu">
            <slot> </slot>
        </ul>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';

export default {
    name: 'UiDropdown',
    components: { UiButton },
    props: {
        variant: {
            type: String,
            default: 'secondary',
            required: false,
        }
    },
};
</script>

<style lang="scss">
.ui-dropdown {
    display: inline-block;
}
</style>
