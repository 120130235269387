<template>
    <div class="ui-button-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ButtonGroup',
};
</script>

<style lang="scss">
.ui-button-group {
    align-items: center;
    display: inline-flex;

    >.ui-button {
        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child, :last-child) {
            border-radius: 0;
        }
        
        &:not(:first-child) {
            margin-left: -1px;
        }
    }

    >.ui-dropdown {
        &:first-child {
            .ui-button {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            .ui-button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:not(:first-child, :last-child) {
            .ui-button {
                border-radius: 0;
            }
        }
        
        &:not(:first-child) {
            .ui-button {
                margin-left: -1px;
            }
        }
    }
}
</style>
